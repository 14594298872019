import * as Yup from 'yup';

export default Yup.object().shape({
    name: Yup.string().required("Product name is required."),
    category: Yup.string().required("Category is required."),
    brand: Yup.string().required("Brand is required."),
    description: Yup.string().required("Description is required."),
    specifications: Yup.string().required("Specifications is required."),
    // featuredImg: Yup.string().required("Featured image is required."),
    // price: Yup.string().required("Price is required."),
    price: Yup.number()
        .min(0.50, "Minimum price is $0.50")
        .max(999999.99, "Maximum price is $999,999.99")
        .required("Price is required"),
    // vat: Yup.string().required("VAT (%) is required."),
});